import { inject }              from 'aurelia-framework';
import { ResourcesRepository } from 'modules/administration/resources/services/repository';
import { MenusRepository }     from 'modules/administration/roles/services/menus-repository';
import { ResourceField }       from 'modules/management/models/resource-field';

@inject(ResourcesRepository, MenusRepository)
export class FormSchema {
    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param resourcesRepository
     * @param menusRepository
     */
    constructor(resourcesRepository, menusRepository) {
        this.resourcesRepository = resourcesRepository;
        this.menusRepository     = menusRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ResourceField}
     */
    model() {
        let model = new ResourceField();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.menu_name = {
            type:         'text',
            key:          'menu_name',
            label:        'form.field.menu',
            size:         4,
            required:     true,
            attributes:   {
                disabled: true,
            },
        };

        this.resource_id = {
            type:         'select2',
            key:          'resource_id',
            label:        'form.field.resource',
            size:         4,
            required:     true,
            remoteSource: () => this.resourcesRepository.active(),
            attributes:   {
                disabled: true,
            },
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.field',
            size:       4,
            required:   true,
            attributes: {
                readonly: true,
            },
        };

        this.helper = {
            type:       'textarea',
            key:        'helper',
            label:      'form.field.helper',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        const schema = [
            [this.menu_name, this.resource_id, this.name],
            [this.helper],
        ];

        if (readonly) {
            this.updater = {
                type:       'text',
                key:        'updater',
                label:      'form.field.updated-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.updated_at = {
                type:       'text',
                key:        'updated_at',
                label:      'form.field.updated-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.updater, this.updated_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('administration.helping-system.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }
}
